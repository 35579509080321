import { useState, useEffect } from "react";

export default function LandingPage() {
  const [showForm, setShowForm] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    job: "",
    netWorth: "",
    description: "",
    referral: "",
  });

  useEffect(() => {
    const stored = localStorage.getItem("pmx-dark");
    setDarkMode(stored === "true");
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    localStorage.setItem("pmx-dark", darkMode.toString());
  }, [darkMode]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:info@pmxmail.com?subject=Request for PMX Mail Invite&body=Name: ${encodeURIComponent(formData.name)}%0D%0AJob & Position: ${encodeURIComponent(formData.job)}%0D%0ANet Worth: ${encodeURIComponent(formData.netWorth)}%0D%0ADescription: ${encodeURIComponent(formData.description)}%0D%0AReferral Member: ${encodeURIComponent(formData.referral)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-white dark:bg-gray-900 text-gray-900 dark:text-white transition-colors duration-300">
      <head>
        <link rel="icon" href="/favicon.ico" />
        <title>PMX Mail</title>
      </head>

      <div className="absolute top-4 right-4">
        <button
          onClick={() => setDarkMode(prev => !prev)}
          className="px-4 py-2 rounded-full bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
        >
          {darkMode ? "☀️ Light" : "🌙 Dark"}
        </button>
      </div>

      <header className="text-center mb-10">
        <img 
          src={darkMode ? "/logo-dark.png" : "/logo.png"} 
          alt="PMX Mail Logo" 
          className="w-40 mx-auto mb-4" 
        />
        <h1 className="text-4xl md:text-5xl font-bold tracking-wide">PMX Mail</h1>
        <p className="text-lg md:text-xl mt-2 text-gray-600 dark:text-gray-400 italic">
          Premium Mail Exchange
        </p>
      </header>

      <section className="text-center max-w-2xl">
        <h2 className="text-2xl md:text-3xl font-semibold mb-4">
          Where Privacy Meets Prestige
        </h2>
        <p className="text-md md:text-lg mb-8 text-gray-700 dark:text-gray-300">
          Elite-level email services for individuals who value discretion, security, and sophistication.
        </p>
         <p className="text-md md:text-lg mb-8 text-gray-700 dark:text-gray-300">
         Brought to you directly from the heart of Switzerland, by ex-Proton Team
        </p>

        {!showForm ? (
          <button
            onClick={() => setShowForm(true)}
            className="bg-gray-900 dark:bg-white text-white dark:text-gray-900 px-6 py-3 rounded-full text-md font-medium hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors duration-200 shadow-md"
          >
            Request an Invite
          </button>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4 text-left max-w-md mx-auto">
            <input type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange}
              className="w-full p-3 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors" required />
            <input type="text" name="job" placeholder="Job & Position" value={formData.job} onChange={handleChange}
              className="w-full p-3 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors" required />
            <input type="text" name="netWorth" placeholder="Estimated Net Worth (USD)" value={formData.netWorth} onChange={handleChange}
              className="w-full p-3 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors" required />
            <textarea name="description" placeholder="Why do you want to join PMX?" value={formData.description} onChange={handleChange} rows="4"
              className="w-full p-3 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors" required />
            <input type="text" name="referral" placeholder="Referral Member (optional)" value={formData.referral} onChange={handleChange}
              className="w-full p-3 border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-gray-900 dark:text-white rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:ring-gray-500 transition-colors" />
            <button type="submit"
              className="w-full py-3 bg-gray-900 dark:bg-white text-white dark:text-gray-900 rounded-full text-md font-medium hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors duration-200 shadow-md">
              Submit Application
            </button>
          </form>
        )}
      </section>

      <footer className="mt-20 text-gray-500 dark:text-gray-400 text-sm text-center">
        © {new Date().getFullYear()} PMX Mail. All rights reserved.
      </footer>
    </div>
  );
}
